import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../styles/ProjectDetail.css';

function ProjectDetail() {
  const { projectId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchProjectDetails();
    fetchProjectConversations();
    fetchProjectFiles();
  }, [projectId]);

  const fetchProjectDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch project details');
      const data = await response.json();
      setProject(data);
    } catch (error) {
      console.error('Error fetching project details:', error);
      setError('Failed to fetch project details. Please try again.');
    }
  };

  const fetchProjectConversations = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/conversations`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch project conversations');
      const data = await response.json();
      setConversations(data);
    } catch (error) {
      console.error('Error fetching project conversations:', error);
      setError('Failed to fetch project conversations. Please try again.');
    }
  };

  const fetchProjectFiles = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/files`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch project files');
      const data = await response.json();
      setFiles(data);
    } catch (error) {
      console.error('Error fetching project files:', error);
      setError('Failed to fetch project files. Please try again.');
    }
  };

  const handleDeleteProject = async () => {
    if (window.confirm('Are you sure you want to delete this project? This action cannot be undone.')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${user.token}`,
            'X-Auth-Provider': user.provider
          }
        });

        if (!response.ok) {
          throw new Error('Failed to delete project');
        }

        setSuccessMessage('Project deleted successfully');
        // Navigate back to the projects list after a short delay
        setTimeout(() => navigate('/projects'), 2000);
      } catch (error) {
        console.error('Error deleting project:', error);
        setError('Failed to delete project. Please try again.');
      }
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        },
        body: formData
      });
      if (!response.ok) throw new Error('Failed to upload file');
      setSuccessMessage('File uploaded successfully');
      setFile(null);
      fetchProjectFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload file. Please try again.');
    }
  };

  const handleRemoveFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/files/${fileId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to remove file');
      setSuccessMessage('File removed successfully');
      fetchProjectFiles();
    } catch (error) {
      console.error('Error removing file:', error);
      setError('Failed to remove file. Please try again.');
    }
  };

  const handleContinueChat = () => {
    navigate('/chat', { state: { pid: projectId },  replace: true  });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleBackToProjects = () => {
    navigate('/projects');
  };


  return (
    <div className="project-detail-page">
       <button onClick={handleBackToProjects} className="back-button">
          ← Back to Projects
        </button>
      <h2 class="h2class">{project?.name}</h2> 
        <button onClick={handleDeleteProject} className="delete-project-button">Delete Project</button>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <div className="project-detail-content">
        <div className="left-column">
          <div className="conversation-section">
      <button onClick={handleContinueChat} className="continue-chat-button">Continue Chat</button>
            <h3>Conversations</h3>
            <ul className="conversation-list">
              {conversations.map(conv => (
                <li key={conv.id} className="conversation-item">
                  <div className="conversation-header">
                    <span className="conversation-date">{formatDate(conv.created_at)}</span>
                    <span className="conversation-user">{conv.user_name}</span>
                  </div>
                  <div className="conversation-details">
                    <span className="conversation-engine">Engine: {conv.engine}</span>
                    <span className="conversation-messages">Messages: {conv.message_count}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="right-column">
          <h3>Project Files</h3>
          <form onSubmit={handleUploadFile} className="file-upload-form">
            <input 
              type="file" 
              onChange={handleFileChange} 
              accept=".pdf,.xlsx,.csv,.php,.js,.sql,.css"
            />
            <button type="submit">Upload File</button>
          </form>
          <ul className="file-list">
            {files.map(file => (
              <li key={file.id}>
                <span>{file.file_name}</span>
                <button onClick={() => handleRemoveFile(file.id)} className="remove-file-button">Remove</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail;