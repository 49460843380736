// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication,LogLevel } from "@azure/msal-browser";
import Login from './components/Login';
import Chat from './components/Chat';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import UserManagement from './components/UserManagement'; 
import ChatHistory from './components/ChatHistory';  // Add this line
import AllChatHistory from './components/AllChatHistory';
import ProjectManagement from './components/ProjectManagement';
import ProjectDetail from './components/ProjectDetail';
import './styles/App.css';

// const msalConfig = {
//   auth: {
//     clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
//     authority: "https://login.microsoftonline.com/common",
//     redirectUri: window.location.origin,
//   },
//   cache: {
//     cacheLocation: "sessionStorage",
//     storeAuthStateInCookie: false,
//   },
// };

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: window.location.origin, // Make sure this matches your Azure AD app registration
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false
    }
  }
};


const msalInstance = new PublicClientApplication(msalConfig); 



 
const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/login" replace />;
};

const AdminRoute = ({ children }) => {
  const { user, loading } = useAuth(); 
  if (loading) {
    return <div>Loading...</div>;
  }

  return user && user.userData.role === 'admin' ? children : <Navigate to="/chat" replace />;
};

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/chat" element={<PrivateRoute><Chat /></PrivateRoute>} />
              <Route path="/user-management" element={<AdminRoute><UserManagement /></AdminRoute>} />
              <Route path="/chat-history/:userId" element={<AdminRoute><ChatHistory /></AdminRoute>} />
              <Route path="/all-chat-history" element={<PrivateRoute><AllChatHistory /></PrivateRoute>} />
              <Route path="/projects" element={<PrivateRoute><ProjectManagement /></PrivateRoute>} />
              <Route path="/project/:projectId" element={<PrivateRoute><ProjectDetail /></PrivateRoute>} />
              <Route path="/" element={<Navigate to="/chat" replace />} />
            </Routes>
          </Router>
        </AuthProvider>
      </GoogleOAuthProvider>
    </MsalProvider>
  );
} 

export default App;


 