import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import PopupForm from './PopupForm';
import '../styles/ProjectManagement.css';

function ProjectManagement() { 
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({ name: '', description: '' });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch projects');
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Failed to fetch projects. Please try again.'); 
        console.log('Token expired. Logging out...');
        logout();
        navigate('/login'); 
    }
  };

  const handleCreateProject = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        },
        body: JSON.stringify(newProject)
      });
      if (!response.ok) { 
        throw new Error('Failed to create project');
      }
      setSuccessMessage('Project created successfully');
      setNewProject({ name: '', description: '' });
      fetchProjects();
      setIsPopupOpen(false);
    } catch (error) {
      console.error('Error creating project:', error);
      setError('Failed to create project. Please try again.');
    }
  };

  const handleBack = () => {
    navigate('/chat');
  };

  return (
    <div className="project-management">
      <div className="project-management-header">
        <h2>Project Management</h2>
        <button onClick={handleBack} className="back-button">Back to Chat</button>
      </div>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      
      <button onClick={() => setIsPopupOpen(true)} className="create-project-button">
        Create New Project
      </button>

      <PopupForm
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onSubmit={handleCreateProject}
        newProject={newProject}
        setNewProject={setNewProject}
      />
 
      <div className="project-list">
        {projects.map(project => (
          <div key={project.id} className="project-box" onClick={() => navigate(`/project/${project.id}`)}>
            <h3>{project.name}</h3>
            <p>{project.description || 'No description provided'}</p>
            <p className="project-date">Created: {new Date(project.created_at).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectManagement;